import styles from '../../pages/Dashboard/Clients/Client/Client.module.css';
import threeDots from '../../images/threeDots.png';
import { dotIconStyles } from '../../utils/globalStyles';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { getMonthName } from '../../utils/helpers';

const Table = ({ pageTitle, createBtnText = null, navigateUrl = '', data }) => {
  const navigate = useNavigate();
  console.log('data', data);
  return (
    <section className={styles.container}>
      <div className={styles.top}>
        <h1 style={{ fontSize: '2rem' }}>{pageTitle}</h1>
        {createBtnText && (
          <div className={styles.icon}>
            <div>
              <Button onClick={() => navigate(navigateUrl)}>{createBtnText}</Button>
            </div>
          </div>
        )}
      </div>

      <div className={styles.project_dashboard_table}>
        <table>
          <thead>
            <tr className={styles.table__header}>
              <th>Amount</th>
              <th>Month</th>
              <th>Year</th>
              <th>BUH</th>
              {pageTitle === 'Expenses' && <th>Description</th>}
              <th></th>
            </tr>
          </thead>

          <tbody className={styles.gfg}>
            {data?.map((e) => (
              <tr key={e._id} className={styles.table__row}>
                <td style={{}}>{Number(e?.amount)}</td>
                <td>{getMonthName(e?.month)}</td>
                <td>{Number(e?.year)}</td>
                <td style={{ color: '#6BDB65', textTransform: 'capitalize' }}>{e?.user?.name}</td>
                {pageTitle === 'Expenses' && <td>{e?.description ?? '-'}</td>}
                <td>
                  <img src={threeDots} alt="Settings-Icon" style={dotIconStyles} />
                </td>
              </tr>
            ))}
          </tbody>
          {data?.length === 0 && (
            <div style={{ color: 'black', padding: '50px 0px' }}>No Data Available</div>
          )}
        </table>
      </div>
    </section>
  );
};

export default Table;
