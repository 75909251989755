import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import styles from './Topbar.module.css';

import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import searchIcon from '../../../images/search-normal.png';
import contactIcon from '../../../images/Envelope.png';
import arrowDownIcon from '../../../images/arrow-square-down.png';

import { useAuthContext } from '../../../hooks/useAuthContext';
import { useInvoiceContext } from '../../../hooks/useInvoiceContext';

const Topbar = () => {
  const { user } = useAuthContext();
  const { isMobile, setIsMobile } = useInvoiceContext();
  const [userSettingData, setUserSettingData] = useState(null);

  const options = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    weekday: 'long',
  };
  const today = new Date();
  const formatter = new Intl.DateTimeFormat('en-GB', options);
  const formattedDate = formatter.format(today);

  useEffect(() => {
    setUserSettingData(JSON.parse(localStorage.getItem('User')));
  }, []);

  return (
    <div className={styles.container}>
      <button className={styles.mobile_menu_btn}>
        {isMobile ? (
          <CloseIcon style={{ fontSize: '20px' }} onClick={() => setIsMobile(false)} />
        ) : (
          <MenuIcon style={{ fontSize: '20px' }} onClick={() => setIsMobile(true)} />
        )}
      </button>

      <div className={styles.topbar__container}>
        {/* OVERVIEW */}
        <div className={styles.overview}>
          <span>Overview</span>
          <span>{formattedDate}</span>
        </div>
        {/* SEARCH */}
        <div className={styles.search__container}>
          <img src={searchIcon} alt="search_icon" />
          <input type="search" placeholder="Type To Search" />
        </div>
        {/* USER */}
        <div className={styles.user__container}>
          {/* <Link to="/dashboard/emailsupport" style={{ marginRight: '3rem' }}>
            <img src={contactIcon} alt="setting-icon" />
          </Link> */}
          <div className={styles.user}>
            <img src={user?.data?.userImage} alt="user-img" />
            <div className={styles.user_detail}>
              <span>{user?.data?.name}</span>
              <span>{user?.data?.role}</span>
            </div>
            <Link to="/dashboard/usersettings">
              <img src={arrowDownIcon} alt="arrowDownIcon" />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Topbar;
