import Sidebar from '../Sidebar';
import Topbar from '../Topbar';
import ErrorBoundary from '../../../components/ErrorBoundaries/ErrorBoundary';
import styles from '../Dashboard.module.css';

const Layout = (props) => {
  return (
    <div className={styles.container}>
      <aside className={styles.sidebar}>
        <Sidebar />
      </aside>
      <main className={styles.main_content}>
        <Topbar />
        <ErrorBoundary>{props.children}</ErrorBoundary>
      </main>
    </div>
  );
};

export default Layout;
