import React, { useState, useEffect } from 'react';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import styles from './Sidebar.module.css';
import { useLogout } from '../../../hooks/useLogout';
import { useInvoiceContext } from '../../../hooks/useInvoiceContext';
import { useAuthContext } from '../../../hooks/useAuthContext';

import TORRELLOGO from '../../../images/torrelLogo.png';
import { InvizLogo } from '../../../images';
import ITEMS from '../../../images/ITEMS.png';
import CLIENTS from '../../../images/Clients.png';
import INVOICES from '../../../images/invoice.png';
import PROJECTS from '../../../images/Projects.png';
import DASHBOARD from '../../../images/Dashboard.png';
import LogoutIcon from '../../../images/logout.png';
import userMIcon from '../../../images/userM.png';
import brandMIcon from '../../../images/brandM.png';
import merchantMIcon from '../../../images/merchantM.png';
import clientMIcon from '../../../images/clientM.png';
import monetaryMIcon from '../../../images/monetaryM.png';

// Dynamic links will be rendered based on user permissions

const links = [
  { path: '/dashboard/main', src: DASHBOARD, text: 'Dashboard' },
  { path: '/dashboard/clients', src: CLIENTS, text: 'Clients' },
  { path: '/dashboard/projects', src: PROJECTS, text: 'Projects' },
  { path: '/dashboard/allinvoices', src: INVOICES, text: 'Invoices' },
  { path: '/dashboard/predefineditems', src: ITEMS, text: 'Items' },
  { path: '/dashboard/user-managment', src: userMIcon, text: 'User Management', isActive: false },
  { path: '/dashboard/brand-managment', src: brandMIcon, text: 'Brand Management' },
  // { path: '/dashboard/merchant-managment', src: merchantMIcon, text: 'Merchant Management' },
  { path: '/dashboard/client-managment', src: clientMIcon, text: 'Client Management' },
  { path: '/dashboard/project-managment', src: ITEMS, text: 'Project Management' },
  { path: '/dashboard/invoice-managment', src: LogoutIcon, text: 'Invoice Management' },
  { path: '/dashboard/monetary-managment', src: monetaryMIcon, text: 'Monetary Management' },
];

const userProtectedRoutes = [
  'User Management',
  'Brand Management',
  'Merchant Management',
  'Client Management',
  'Project Management',
  'Invoice Management',
  'Monetary Management',
];
const adminProtectedRoutes = ['Clients', 'Projects', 'Invoices', 'Items'];

const Sidebar = () => {
  const { isMobile, setIsMobile } = useInvoiceContext();
  const navigate = useNavigate();
  const { logout } = useLogout();
  const { user } = useAuthContext();
  const [userfilteredLinks, setUserFilteredLinks] = useState([]);
  const [adminfilteredLinks, setAdminFilteredLinks] = useState([]);

  useEffect(() => {
    setUserFilteredLinks(links.filter((link) => !userProtectedRoutes.includes(link.text)));
    setAdminFilteredLinks(links.filter((link) => !adminProtectedRoutes.includes(link.text)));
  }, []);

  const logoutHandler = () => {
    logout();
    navigate('/login');
  };

  return (
    <div className={isMobile ? styles.mobile_container : styles.container}>
      <div className={styles.img__container}>
        <img src={InvizLogo} alt="Logo" onClick={() => navigate('/')} />
      </div>

      <div className={styles.buttons}>
        {user?.data?.role === 'admin' &&
          adminfilteredLinks?.map((link, index) => (
            <NavLink
              to={link.path}
              key={index}
              style={({ isActive }) => ({
                borderBottom: isActive ? '#1b9de4 solid 4px' : '',
                opacity: isActive ? 1 : '',
              })}
              // className={styles.link_active}
            >
              <img src={link.src} alt={link.text} />
              <span onClick={() => setIsMobile(false)}> {link.text}</span>
            </NavLink>
          ))}
        {user?.data?.role === 'user' &&
          userfilteredLinks?.map((link, index) => (
            <NavLink
              to={link.path}
              key={index}
              style={({ isActive }) => ({
                borderBottom: isActive ? '#1b9de4 solid 4px' : '',
                opacity: isActive ? 1 : '',
              })}
              // className={styles.link_active}
            >
              <img src={link.src} alt={link.text} />
              <span onClick={() => setIsMobile(false)}> {link.text}</span>
            </NavLink>
          ))}
        <Link onClick={logoutHandler} to="/login" style={{ marginTop: '10px' }}>
          <img src={LogoutIcon} alt="logout_icon" />
          <span>Logout</span>
        </Link>
      </div>
    </div>
  );
};

export default Sidebar;
