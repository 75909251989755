import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { useAuthContext } from './hooks/useAuthContext';
import { Navigate } from 'react-router-dom';
import Targets from './pages/Dashboard/MonetaryManagement/Targets';
import Budgets from './pages/Dashboard/MonetaryManagement/Budgets';
import Acheived from './pages/Dashboard/MonetaryManagement/Acheived';
import Expenses from './pages/Dashboard/MonetaryManagement/Expenses';
import Chargebacks from './pages/Dashboard/MonetaryManagement/Chargebacks';
import Refunds from './pages/Dashboard/MonetaryManagement/Refunds';
import SetTarget from './pages/Dashboard/MonetaryManagement/SetTarget';
import SetBudget from './pages/Dashboard/MonetaryManagement/SetBudget';
import PaymentCancel from './pages/ClientInvoice/PaymentCancel';
const UpdateInvoices = React.lazy(() => import('./pages/Dashboard/UpdateInvoices'));
const ClientInvoice = React.lazy(() => import('./pages/ClientInvoice'));
const Thankyou = React.lazy(() => import('./pages/ClientInvoice/Thankyou/Thankyou.jsx'));
const UsersManagment = React.lazy(() => import('./pages/Dashboard/usersManagment'));
const ProjectManagement = React.lazy(
  () => import('./pages/Dashboard/ProjectManagement/ProjectManagement'),
);
const InvoiceManagement = React.lazy(() => import('./pages/Dashboard/InvoiceManagement'));
const MonetaryManagement = React.lazy(() => import('./pages/Dashboard/MonetaryManagement'));
const ClientManagement = React.lazy(() => import('./pages/Dashboard/ClientManagement'));
const BrandManagement = React.lazy(
  () => import('./pages/Dashboard/BrandManagement/BrandManagement'),
);
const UpdateUsersManagment = React.lazy(() => import('./pages/Dashboard/UpdateUsersManagment'));
const CreateBrand = React.lazy(() => import('./pages/Dashboard/BrandManagement/CreateBrand'));
const Confirmation = React.lazy(() => import('./pages/Auth/Confirmation/Confirmation'));
const Login = React.lazy(() => import('./pages/Auth/Login/Login'));
const Signup = React.lazy(() => import('./pages/Auth/Signup/Signup'));
const Dashboard = React.lazy(() => import('./pages/Dashboard'));
const Clients = React.lazy(() => import('./pages/Dashboard/Clients'));
const CreateInvoices = React.lazy(() => import('./pages/Dashboard/CreateInvoices'));
const CreateInvoices2 = React.lazy(() => import('./pages/Dashboard/createinvoices2'));
const CreateProjects = React.lazy(() => import('./pages/Dashboard/CreateProjects'));
const InvoiceA4 = React.lazy(() => import('./pages/Dashboard/InvoiceA4'));
const Invoices = React.lazy(() => import('./pages/Dashboard/invoices'));
const Projects = React.lazy(() => import('./pages/Dashboard/Projects'));
const SlicedInvoices = React.lazy(() => import('./pages/Dashboard/SlicedInvoices'));
const LandingPage = React.lazy(() => import('./pages/LandingPage/LandingPage'));
const CreateClients = React.lazy(() => import('./pages/Dashboard/CreateClients'));
const ClientDetails = React.lazy(() => import('./pages/Dashboard/ClientDetails/ClientDetails'));
const UpdateClients = React.lazy(() => import('./pages/Dashboard/UpdateClients'));
const AllProject = React.lazy(() => import('./pages/Dashboard/AllProject/AllProject'));
const ForgotPassword = React.lazy(() => import('./pages/Auth/ForgotPassword/ForgotPassword'));
const ResetPassword = React.lazy(() => import('./pages/Auth/ResetPassword/ResetPassword'));
const AllInvoices = React.lazy(() => import('./pages/Dashboard/allInvoices'));
const EmailSupports = React.lazy(() => import('./pages/Dashboard/EmailSupports'));
const UserSettings = React.lazy(() => import('./pages/Dashboard/UserSettings'));
const ClientSettings = React.lazy(() => import('./pages/Dashboard/ClientSettings'));
const UpdateUsers = React.lazy(() => import('./pages/Dashboard/UpdateUsers'));
const PrefinedItems = React.lazy(() => import('./pages/Dashboard/PredefinedItems'));
const CreatePrefinedItems = React.lazy(() => import('./pages/Dashboard/CreatePredefinedItems'));
const UpdatePredefineItems = React.lazy(() => import('./pages/Dashboard/UpdatePredefineItems'));
const SelectClients = React.lazy(() => import('./pages/Dashboard/SelectClients'));
const SelectProjects = React.lazy(() => import('./pages/Dashboard/SelectProjects'));
const CreateBusinesses = React.lazy(() => import('./pages/Dashboard/CreateBusinesses'));
const UpdateBusinesses = React.lazy(() => import('./pages/Dashboard/UpdateBusinesses'));
const TermsAndConditions = React.lazy(
  () => import('./pages/TermsAndConditions/TermsAndConditions'),
);
const ProjectDetails = React.lazy(() => import('./pages/Dashboard/ProjectDetails'));
const UpdateBrand = React.lazy(() => import('./pages/Dashboard/BrandManagement/UpdateBrand'));

const AppRouting = () => {
  const { user } = useAuthContext();

  const forgotUser = JSON.parse(localStorage.getItem('forgot-user'));

  return (
    <Router>
      <Routes>
        <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
        <Route
          path="/"
          element={user?.data?.emailVerified ? <Navigate to="/dashboard/main" /> : <LandingPage />}
        />
        <Route
          path="/signup/:token"
          element={user?.data?.emailVerified ? <Navigate to="/dashboard/main" /> : <Signup />}
        />
        <Route path="/forgotpassword" element={!user ? <ForgotPassword /> : <Navigate to="/" />} />
        {forgotUser && (
          <Route
            path={`/api/v1/users/reset-password/${forgotUser._id}`}
            element={!user ? <ResetPassword /> : <Navigate to="/" />}
          />
        )}
        <Route
          path="/login"
          element={user?.data?.emailVerified ? <Navigate to="/dashboard/main" /> : <Login />}
        />
        <Route path="/confirmation" element={user ? <Confirmation /> : <Navigate to="/login" />} />
        <Route
          path="/dashboard/main"
          element={user?.data?.emailVerified ? <Dashboard /> : <Navigate to="/login" />}
        />
        <Route path="/dashboard/clients" element={user ? <Clients /> : <Navigate to="/login" />} />
        <Route
          path="/dashboard/projects"
          element={user ? <AllProject /> : <Navigate to="/login" />}
        />
        <Route
          path="/dashboard/projects/selectclients"
          element={user ? <SelectClients /> : <Navigate to="/login" />}
        />
        <Route
          path="/dashboard/projects/:id"
          element={user ? <Projects /> : <Navigate to="/login" />}
        />
        <Route
          path="/dashboard/projects/create/:id"
          element={user ? <CreateProjects /> : <Navigate to="/login" />}
        />
        <Route
          path="/dashboard/projectDetails"
          element={user ? <ProjectDetails /> : <Navigate to="/login" />}
        />
        <Route
          path="/dashboard/allinvoices"
          element={user ? <AllInvoices /> : <Navigate to="/login" />}
        />
        <Route
          path="/dashboard/projects/selectprojects"
          element={user ? <SelectProjects /> : <Navigate to="/login" />}
        />
        <Route
          path="/dashboard/invoices/:id"
          element={user ? <Invoices /> : <Navigate to="/login" />}
        />
        <Route
          path="/dashboard/invoices/create/:id"
          element={user ? <CreateInvoices /> : <Navigate to="/login" />}
        />
        <Route
          path="/dashboard/invoices/slicedinvoice"
          element={user ? <SlicedInvoices /> : <Navigate to="/login" />}
        />
        <Route
          path="/dashboard/invoice/createinvoice2/:id"
          element={user ? <CreateInvoices2 /> : <Navigate to="/login" />}
        />
        <Route
          path="/dashboard/invoiceprint/:id"
          element={user ? <InvoiceA4 /> : <Navigate to="/login" />}
        />
        <Route path="/invoice/:id" element={<ClientInvoice />} />
        <Route path="/completion" element={<Thankyou />} />
        <Route path="/payment_cancel" element={<PaymentCancel />} />
        <Route
          path="/dashboard/createClients"
          element={user ? <CreateClients /> : <Navigate to="/login" />}
        />
        <Route
          path="/dashboard/clients/:id"
          element={user ? <ClientDetails /> : <Navigate to="/login" />}
        />
        <Route
          path="/dashboard/updateClients"
          element={user ? <UpdateClients /> : <Navigate to="/login" />}
        />
        <Route
          path="/dashboard/usersettings"
          element={user ? <UserSettings /> : <Navigate to="/login" />}
        />
        <Route
          path="/dashboard/createbusiness"
          element={user ? <CreateBusinesses /> : <Navigate to="/login" />}
        />
        <Route
          path="/dashboard/updatebusiness"
          element={user ? <UpdateBusinesses /> : <Navigate to="/login" />}
        />
        <Route
          path="/dashboard/updateinvoice"
          element={user ? <UpdateInvoices /> : <Navigate to="/login" />}
        />
        <Route
          path="/dashboard/updateusers"
          element={user ? <UpdateUsers /> : <Navigate to="/login" />}
        />
        <Route
          path="/dashboard/clientsettings"
          element={user ? <ClientSettings /> : <Navigate to="/login" />}
        />
        <Route
          path="/dashboard/predefineditems"
          element={user ? <PrefinedItems /> : <Navigate to="/login" />}
        />
        <Route
          path="/dashboard/createpredefineditems"
          element={user ? <CreatePrefinedItems /> : <Navigate to="/login" />}
        />
        <Route
          path="/dashboard/updatepredefineditems"
          element={user ? <UpdatePredefineItems /> : <Navigate to="/login" />}
        />
        <Route path="/dashboard/emailsupport" element={<EmailSupports />} />
        <Route path="/dashboard/user-managment" element={<UsersManagment />} />
        <Route path="/dashboard/brand-managment" element={<BrandManagement />} />
        <Route path="/dashboard/brand-managment/create" element={<CreateBrand />} />
        <Route path="/dashboard/brand-managment/:id" element={<UpdateBrand />} />
        <Route path="/dashboard/merchant-managment" element={<UsersManagment />} />
        <Route path="/dashboard/client-managment" element={<ClientManagement />} />
        <Route path="/dashboard/project-managment" element={<ProjectManagement />} />
        <Route path="/dashboard/invoice-managment" element={<InvoiceManagement />} />
        <Route path="/dashboard/monetary-managment/targets" element={<Targets />} />
        <Route path="/dashboard/monetary-managment/set-target" element={<SetTarget />} />
        <Route path="/dashboard/monetary-managment/set-budget" element={<SetBudget />} />
        <Route path="/dashboard/monetary-managment/budgets" element={<Budgets />} />
        <Route path="/dashboard/monetary-managment/acheived" element={<Acheived />} />
        <Route path="/dashboard/monetary-managment/expenses" element={<Expenses />} />
        <Route path="/dashboard/monetary-managment/chargebacks" element={<Chargebacks />} />
        <Route path="/dashboard/monetary-managment/refunds" element={<Refunds />} />
        <Route path="/dashboard/monetary-managment" element={<MonetaryManagement />} />

        <Route path="/dashboard/user-managment/:id" element={<UpdateUsersManagment />} />
        <Route
          path="*"
          element={
            <h1 style={{ textAlign: 'center', marginTop: '2rem' }}>Error 404! PAGE NOT FOUND</h1>
          }
        />
      </Routes>
    </Router>
  );
};

export default AppRouting;
