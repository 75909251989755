import 'react-toastify/dist/ReactToastify.css';
import 'react-multi-email/dist/style.css';
import 'react-tooltip/dist/react-tooltip.css';
import './styles/index.css';
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { AuthContextProvider } from './context/AuthContext';
import { InvoiceContextProvider } from './context/InvoiceContext';
import { ToastContainer } from 'react-toastify';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <AuthContextProvider>
      <InvoiceContextProvider>
        <App />
        <ToastContainer />
      </InvoiceContextProvider>
    </AuthContextProvider>
  </React.StrictMode>,
);
