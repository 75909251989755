import React from 'react';

const PaymentCancel = () => {
  return (
    <div>
      <br />
      <br />

      <center>
        <h4>Your payment could not be processed at the moment</h4>
      </center>
    </div>
  );
};

export default PaymentCancel;
