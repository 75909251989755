// const handleFile = (e) => {
//   const file = e.target.files[0];

//   if (!file) {
//     alert('Please select a File to upload!');
//     return;
//   }

//   transformFile(file);
// };

// const transformFile = (file) => {
//   const reader = new FileReader();
//   if (file) {
//     reader.readAsDataURL(file);
//     reader.onloadend = () => {
//       setSelectedFile(reader.result);
//     };
//   } else {
//     setSelectedFile('');
//   }
// };

export const FileHandler = (e) => {
  const file = e.target.files[0];

  if (!file) {
    alert('Please select a File to upload!');
    return;
  }
  // console.log(1, file);
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onloadend = () => {
    return reader.result ? reader.result : '';
  };
};

// export const yearsList = () => {
//   let currentYear = new Date().getFullYear();
//   let years = Array.from({ length: 20 }, (_, index) => (currentYear - index).toString());
//   return years;
// };

export const yearsList = () => {
  const yearsArray = [];
  for (let year = 2024; year >= 2004; year--) {
    yearsArray.push(year);
  }
  return yearsArray;
};

export function getMonthName(monthNumber) {
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  // Check if the monthNumber is within a valid range
  if (monthNumber >= 1 && monthNumber <= 12) {
    return months[monthNumber - 1];
  } else {
    // Handle invalid monthNumber
    return 'Invalid Month';
  }
}

export function findCommonElements(array1, array2) {
  return array1.filter((element) => array2.includes(element));
}

export const renderCheckboxes = (items, selectedItems, handleCheckboxChange) => {
  return (
    <div>
      {items &&
        items.length > 0 &&
        items.map((item, i) => (
          <div key={i}>
            <input
              type="checkbox"
              onChange={() => handleCheckboxChange(item._id)}
              checked={selectedItems.includes(item._id)}
            />
            <span>{item?.name}</span>
          </div>
        ))}
    </div>
  );
};
