import React, { useState, useEffect } from 'react';
import classes from './Loader.module.css';

const Loading = () => {
  const [rotation, setRotation] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      // Increment the rotation angle on each interval
      setRotation((prevRotation) => prevRotation + 10);
    }, 100);

    // Cleanup the interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  return <div className={classes.loader} style={{ transform: `rotate(${rotation}deg)` }} />;
};

export default Loading;
