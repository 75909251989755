import React from 'react';
import Form from '../../../components/ReusableForm/Form';
import Layout from '../Layout';

const SetBudget = () => {
  return (
    <Layout>
      <Form pageTitle="Set Budget" postUrl="/api/v1/admin/budget" dispatchType="SET_BUDGETS"/>
    </Layout>
  );
};

export default SetBudget;
