import React, { useEffect } from 'react';
import Form from '../../../components/ReusableForm/Form';
import Layout from '../Layout';

const SetTarget = () => {
  return (
    <Layout>
      <Form pageTitle="Set Target" postUrl="/api/v1/admin/target" dispatchType="SET_TARGETS" />
    </Layout>
  );
};

export default SetTarget;
