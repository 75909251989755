import { useState } from 'react';
import { yearsList } from '../../utils/helpers';
import { Link, useNavigate } from 'react-router-dom';
import classes from '../../pages/Dashboard/UserSettings/UserSettings/UserSetting.module.css';
import OptionSelector from '../OptionSelector/OptionSelector';
import { useEffect } from 'react';
import { useAuthContext } from '../../hooks/useAuthContext';
import useApi from '../../hooks/useApi';
import { useInvoiceContext } from '../../hooks/useInvoiceContext';
import useToast from '../../hooks/useToast';

const Form = ({ pageTitle, postUrl = '', dispatchType }) => {
  const { showSuccessToast,showErrorToast } = useToast();
  const { user } = useAuthContext();
  const { users, dispatch } = useInvoiceContext();
  const navigate = useNavigate();
  const { error, loading, apiCall } = useApi();
  const [amount, setAmount] = useState(null);
  const [selectedBusinessUnit, setBusinessUnit] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [selectedYear, setSelectedYear] = useState(null);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const data = await apiCall('/api/v1/admin/user');
        if (data) {
          dispatch({
            type: 'GET_USERS',
            payload: data?.data,
          });
        }
      } catch (error) {
        console.log('Fetch Error', error);
      }
    };
    fetchUsers();
  }, [user]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if(!user){
      return showErrorToast("Please select Business Unit Head.");
    }
    const payload = {
      user: selectedBusinessUnit,
      amount,
      month: selectedMonth,
      year: selectedYear,
    };
    try {
      const data = await apiCall(postUrl, 'post', payload);
      if (data) {
        dispatch({
          type: dispatchType,
          payload: data?.data,
        });
        showSuccessToast(`${pageTitle.split(' ')[1]} created successfully!`);
        navigate(-1);
      }
    } catch (error) {
      console.log('Post-Error', error);
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes.heading}>
        <h1 style={{ margin: '2rem 0', fontSize: '2.2rem', color: '#1b9de4' }}>{pageTitle}</h1>
      </div>
      <div className={classes.form__container}>
        <form className={classes.form__control} onSubmit={handleSubmit}>
          <div className={classes.inputContainer}>
            <label>
              <span>Amount *</span>
              <input
                type="Number"
                placeholder="Enter target amount."
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
              />
            </label>
            <label>
              <span>Select Month *</span>
              <select
                onChange={(e) => setSelectedMonth(e.target.value)}
                className={classes.BUH_selection}
              >
                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]?.map((b) => (
                  <OptionSelector optionTitle="Select Month" key={b} item={b} />
                ))}
              </select>
            </label>
          </div>

          <div className={classes.inputContainer}>
            <label>
              <span>Select Year *</span>
              <select
                onChange={(e) => setSelectedYear(e.target.value)}
                className={classes.BUH_selection}
              >
                {yearsList()?.map((b) => (
                  <OptionSelector optionTitle="Select Year" key={b} item={b} />
                ))}
              </select>
            </label>
            <label>
              <span>Select Business Unit Head</span>
              <select
                onChange={(e) => setBusinessUnit(e.target.value)}
                className={classes.BUH_selection}
              >
                {users?.map((b) => (
                  <OptionSelector optionTitle="Select Business Unit" key={b._id} item={b} />
                ))}
              </select>
            </label>
          </div>
          {error && <p style={{ color: 'red', fontSize: '0.8rem', margin: '20px 0' }}>{error}</p>}
          {loading ? (
            <button style={{ opacity: '0.6' }} disabled>
              Creating...
            </button>
          ) : (
            <button type="submit" style={{ marginTop: '40px' }}>
              Create
            </button>
          )}

          <Link onClick={() => navigate(-1)}>
            <button style={{ marginLeft: '20px' }} className={classes.btn}>
              Cancel
            </button>
          </Link>
        </form>
      </div>
    </div>
  );
};

export default Form;
